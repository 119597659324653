import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RatingModule } from 'primeng/rating';
import { TableModule } from 'primeng/table';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { HeadingComponent } from '../../../shared/components/heading/heading.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserClientVm } from '../../../store/user/user.interface';
import { UserService } from '../../services/user.service';
import { ReviewerService } from '../../services/reviewer.service';
import { HelperService } from '../../services/helper.service';
import { DocumentService } from '../../services/document-service';
import { ToastService } from '../../services/toast.service';
import { Credential, Criteria, ReviewerProfile, Tenant } from '../../../store/reviewer/reviewer.interface';
import { constants, UserRoles } from '../../constants/constants';
import { CommonModule } from '@angular/common';
import { Document } from '../../../modules/reviewer/profile/profile.interface';
import { AddToTalentPoolListComponent } from '../add-to-talent-pool-list/add-to-talent-pool-list.component'
import { TooltipModule } from 'primeng/tooltip';
import { PeopleService } from '../../services/people.service';
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service';
import { UpdateUserStatus } from '../../../modules/client/dashboard/people/people.interface';
import { InputSwitchModule } from 'primeng/inputswitch';
import { UpdateUserDataComponent } from '../update-user-data/update-user-data.component';
import { RemovedSkillsCredentialsComponent } from '../removed-skills-credentials/removed-skills-credentials.component';
@Component({
  selector: 'app-reviewer-profile',
  standalone: true,
  imports: [HeadingComponent, AvatarModule, RatingModule, TableModule, FormsModule, ButtonModule, InputTextareaModule, CommonModule, AddToTalentPoolListComponent, TooltipModule, InputSwitchModule],
  templateUrl: './reviewer-profile.component.html',
  styleUrl: './reviewer-profile.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ReviewerProfileComponent implements OnInit {
  currentUser!: UserClientVm;
  showMore: boolean = false;
  maxLength: number = 200;
  headingButton = {
    url: '/client/project/create',
    label: 'INVITE CANDIDATE'
  }
  newNote: string = '';
  user!: ReviewerProfile;
  pastProjects: any[] = [];
  docuSignDocuments: any[] = [];
  firmReviewerDocuments: any[] = [];
  notes: any[] = [];
  talentPoolAssigned: any[] = [];
  showTalentPoolListing: boolean = false;
  showEditSkillExperience: boolean = false;
  showEditCredentials: boolean = false;
  reviewerInactiveCriteria: Criteria[] = [];
  reviewerInactiveCredentials: Credential[] = [];
  secureBackgroundStatus = constants.secureBackgroundStatus;
  isAdminApprovalPending = false;

  constructor(private reviewerDetailRef: DynamicDialogRef, public config: DynamicDialogConfig, private userService: UserService,
    private dialogService: DialogService,
    private reviewerService: ReviewerService, private helperService: HelperService, private documentService: DocumentService,
    private toast: ToastService, private peopleService: PeopleService, private confirmationDialogService: ConfirmationDialogService
  ) { }

  ngOnInit() {
    this.currentUser = this.userService.getSessionUser();
    this.getReviewerDetails();
    this.getRevewerPastProjects();
    this.getReviewerDocuSignDocuments();
    this.getFirmReviewerDocuments();
    this.getAllTalentPoolByReviewerId();
  }

  toggleShow() {
    this.showMore = !this.showMore;
  }

  getAllTalentPoolByReviewerId() {
    this.reviewerService.getAllTalentPoolByReviewerId(this.config.data.reviewerId).subscribe((resp: any) => {
      this.talentPoolAssigned = resp;
    })
  }

  getFirmReviewerDocuments() {
    const params = {
      reviewerId: this.config.data.reviewerId,
      firmId: this.currentUser.firm._id
    };
    this.reviewerService.getFirmReviewerDocuments(params).subscribe((resp: any) => {

      resp.map((d: any) => {
        d.url = this.documentService.getDocumentPathById(d._id);
      })
      this.firmReviewerDocuments = resp;
    })
  }

  getReviewerDocuSignDocuments() {
    this.reviewerService.getReviewerDocuSignDocuments(this.config.data.reviewerId, this.currentUser.firm._id).subscribe((resp: any) => {
      this.docuSignDocuments = resp.filter((d: any) => {
        return d.firm === this.currentUser.firm._id;
      })

    })
  }

  favouriteUser() {
    if(this.user.isFavorite){
      this.removeAsFavourite();
    }else{
      this.setAsFavourite();
    }
  }

  removeAsFavourite() {
    this.peopleService.removeFromFavouriteList(this.currentUser.firm._id, this.user._id).subscribe({
      next: () => {
        this.user.isFavorite = false
      }
    });
  }

  setAsFavourite() {
    const fav = {
      favorite: this.user._id
    };
    this.peopleService.addToFavouriteList(this.currentUser.firm._id, fav).subscribe({
      next: () => {
        this.user.isFavorite = true;
      },
    });
  }

  getRevewerPastProjects() {
    this.reviewerService.getReviewerAllPastProjects(this.config.data.reviewerId).subscribe({
      next: (resp) => {this.pastProjects = resp;}
    })
  }

  getReviewerDetails() {
    this.reviewerService.getReviewerProfile(this.config.data.reviewerId, this.currentUser.firm._id).subscribe((resp: ReviewerProfile) => {
      this.user = resp;
      this.user.profile.contact.home = this.helperService.formatPhoneNo(this.user.profile.contact.home);
      const currentTenant = this.user.tenants.find((tenant: any) => tenant.firm === this.currentUser.firm._id) as Tenant;
      this.isAdminApprovalPending = currentTenant.reviewerStatus === constants.userVerificationStatus.pendingAdminVerification;
      this.reviewerInactiveCriteria = this.user.profile.criterias.filter((criteria) => criteria.status === 'Inactive');
      this.user.profile.criterias = this.user.profile.criterias.filter((criteria) => criteria.status === 'Active');
      this.reviewerInactiveCredentials = this.user.profile.credentials.filter((credential) => credential.status === 'Inactive');
      this.user.profile.credentials = this.user.profile.credentials.filter((credential) => credential.status === 'Active');

      this.user.profile.criterias.forEach((criteria) => {
        criteria.criteriaSelectedOptions = criteria.criteriaSelectedOption.map((val) => val.val).join(',');
      })
      this.user.status = currentTenant.isActive;
      this.user.isFavorite = this.user.favorite && this.user.favorite.favorites && this.user.favorite.favorites.length ? this.user.favorite.favorites.indexOf(this.user._id) > -1 : false;
      this.user.documents.forEach((d: any) => {
        if (d.documentType !== constants.documentTypes.resume)
          d.url = this.documentService.getDocumentPathById(d._id);
      })
    })
  }

  status(data: any): string {
    if (data.secureBackgroundResult === this.secureBackgroundStatus.meetsRequirement) {
      return 'Verified';
    } else if (data.secureBackgroundStatus === this.secureBackgroundStatus.pending || data.secureBackgroundResult === this.secureBackgroundStatus.needsFurtherReview) {
      return 'Verification in progress';
    } else if (data.secureBackgroundResult === this.secureBackgroundStatus.doesntMeetsRequirement) {
      return 'Not verified';
    }
    return data.secureBackgroundResult;
  }

  onStatusToggleSwitch($event: Event, reviewer: any) {
    const mesg = `Are you sure you want to ${reviewer.status ? 'activate' : 'deactivate'} ${reviewer.firstName} ${reviewer.lastName}. ${reviewer.status ? '' : 'Deactivated candidates are not eligible to receive offers until reactivated'}`
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        const data: UpdateUserStatus = {
          userId: reviewer._id,
          status: reviewer.status
        }
        this.peopleService.updateUserStatusByFirm(data).subscribe({
          next: (resp) => {
            this.toast.showSuccess('User Update Successfully');
          },
          error: (error) => {
            reviewer.status = !reviewer.status;
          }
        });
      },
      reject: () => {
        reviewer.status = !reviewer.status;
      }
    });
  }

  getDocumentPathById(docId: string){
    window.open(this.documentService.getDocumentPathById(docId) || '', '_blank');
  }

  openDocuSignDocument(envelopId: string, documentId: string, documentName: string) {
    this.reviewerService.viewEnvelopeDocument(envelopId, documentId).subscribe((res: any) => {
      let uri = "data:application/octet-stream," + window.escape(res.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = uri;
      downloadLink.download = documentName + ".pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  addNote() {
    const note = this.newNote;
    this.reviewerService.addNote( this.config.data.reviewerId, note).subscribe((resp: any) => {
      this.newNote = '';
      this.user.notes.push(resp);
    })
  }

  onUploadDocument(event: any, type: string): void {
    const file = event.target.files[0];
    const opts = {
      documentType: type,
      firmId: this.currentUser.firm._id,
      reviewerId: this.config.data.reviewerId
    };
    this.uploadDocument(file, opts);
  }

  uploadDocument(file: any, opts: any) {

    this.documentService.uploadDocument(file, opts).subscribe((d: any) => {
      this.toast.showSuccess('Document uploaded successfully');
      this.getFirmReviewerDocuments();
    })
  }

  editDocument(event: any, type: string, data: Document) {
    const addDocument = { file: event.target.files[0], opts: {} };
    addDocument.opts = {
      documentType: type,
      firmId: this.currentUser.firm._id,
      reviewerId: this.config.data.reviewerId
    };
    this.deleteDocment(data._id, true, addDocument)
  }

  deleteDocment(id: string, edit?: boolean, data?: any) {
    this.documentService.deleteDocument(id).subscribe((d: any) => {
      if (edit) {
        this.uploadDocument(data.file, data.opts);
      } else {
        this.toast.showSuccess('Document deleted successfully');
        this.getFirmReviewerDocuments();
      }
    })
  }

  showTalentPool() {
    this.showTalentPoolListing = !this.showTalentPoolListing
  }

  currentUserisFM(){
    return this.currentUser.role === UserRoles.firmManager;
  }

  getMappedData(data: any){
    return data.map((res: any) => {return {name: res.name, id: res._id}});
  }

  editUserDetails(type: string){
    this.reviewerDetailRef = this.dialogService.open(UpdateUserDataComponent, {
      data: {
        type: type,
        firmId: this.currentUser.firm._id,
        userId: this.user._id,
        userName: `${this.user.firstName} ${this.user.lastName}`,
        selectedItems: type === 'Search Market' ? this.getMappedData(this.user.profile.searchMarkets) : this.getMappedData(this.user.profile.jobCategories)
      },
      header: `Edit ${type} for ${this.user.firstName} ${this.user.lastName}`,
      width: '30vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    this.reviewerDetailRef.onClose.subscribe((data: any) => {
      if (data) {
        this.toast.showSuccess(`${type}s Updated Successfully`);
        this.getReviewerDetails();
      }
    });
  }

  showSkillExperienceEdit(){
    this.showEditSkillExperience = !this.showEditSkillExperience;
  }

  editSkillExperience(id: string){
    this.deactivateSkills(id);
    let index = this.user.profile.criterias.findIndex((res) => res._id == id);
    this.user.profile.criterias.splice(index, 1);
  }

  showCredentialsEdit(){
    this.showEditCredentials = !this.showEditCredentials;
  }

  editCredentials(id: string){
    this.deactivateCredential(id);
    let index = this.user.profile.credentials.findIndex((res) => res.certificate == id);
    this.user.profile.credentials.splice(index, 1);
  }

  showRemovedSkills(){
    if(this.reviewerInactiveCriteria.length){
      this.reviewerDetailRef = this.dialogService.open(RemovedSkillsCredentialsComponent, {
        data: {
          firmId: this.currentUser.firm._id,
          userId: this.user._id,
          data: this.reviewerInactiveCriteria,
          type: 'Skills'
        },
        header: `Removed Skills of ${this.user.firstName} ${this.user.lastName}`,
        width: '30vw',
        contentStyle: { overflow: 'auto' },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        }
      });
  
      this.reviewerDetailRef.onClose.subscribe((data: any) => {
        if (data) {
          this.toast.showSuccess('Skills Activated Successfully');
          this.getReviewerDetails();
        }
      });
    }
    else {
      this.toast.showSuccess('No Removed Skills Found');
    }
  }

  showRemovedCredentials(){
    if(this.reviewerInactiveCredentials.length){
      this.reviewerDetailRef = this.dialogService.open(RemovedSkillsCredentialsComponent, {
        data: {
          firmId: this.currentUser.firm._id,
          userId: this.user._id,
          data: this.reviewerInactiveCredentials,
          type: 'Credentials'
        },
        header: `Removed Credentials of ${this.user.firstName} ${this.user.lastName}`,
        width: '30vw',
        contentStyle: { overflow: 'auto' },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        }
      });
  
      this.reviewerDetailRef.onClose.subscribe((data: any) => {
        if (data) {
          this.toast.showSuccess('Credentials Activated Successfully');
          this.getReviewerDetails();
        }
      });
    }
    else {
      this.toast.showSuccess('No Removed Credentials Found');
    }
  }

  deactivateSkills(id: string){
    let body = {
      userId: this.user._id,
      idToUpdate: id
    };
    this.reviewerService.deactivateTenantCriteria(this.currentUser.firm._id, body).subscribe((res) => {
      this.toast.showSuccess('Skill Deactivated Successfully');
      this.getReviewerDetails();
    });
  }

  deactivateCredential(id: string){
    let body = {
      userId: this.user._id,
      idToUpdate: id
    };
    this.reviewerService.deactivateTenantCredential(this.currentUser.firm._id, body).subscribe((res) => {
      this.toast.showSuccess('Credential Deactivated Successfully');
      this.getReviewerDetails();
    });
  }
}
